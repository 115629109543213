<template>
    <div>
        <van-swipe :autoplay="3000" style="height: 200px;">
            <van-swipe-item v-for="(item,index) in activity.goods_imgs" :key="index" @click="imageDetail(index)"
                            style="text-align: center;">
                <van-image :src="item" height="100%" alt="" fit="contain"/>
            </van-swipe-item>
        </van-swipe>

        <van-cell-group>
            <van-cell>
                <template #title>
                    <span style="font-size: 16px;">{{activity.goods_name}}</span>
                    <van-tag type="danger" size="medium" style="float: right;">{{activity.shop_type_name}}</van-tag>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <van-tag plain type="danger" size="medium" style="margin-right: 5px;">第{{activity.act_no}}期</van-tag>
                </template>
                <div>
                    <van-button size="mini" plain round style="padding: 0 8px;font-size: 14px;" type="danger"
                                @click="$router.push({ path: '/lottery/record', query: { act_id: act_id }})">
                        <van-icon style="float: left;margin-right:3px;" name="contact"/>
                        <span>{{activity.total}}</span>
                    </van-button>
                    人已参与
                </div>
                <template #label>
                    <div class="van-cell__label">
                        <span style="font-size: 12px;">{{activity.end_time}}截止</span>
                    </div>
                </template>
            </van-cell>
            <van-cell title="团购链接" @click="toLink(activity.detail)" is-link >点击查看</van-cell>
            <van-cell :title="activity.shop_name" @click="toLink(activity.link_url)" is-link>
                <div>点击查看</div>
                <template #label>
                    <p style="margin: 3px 0;">{{activity.short_name}}</p>
                    <p style="margin: 3px 0;">{{activity.address}}</p>
                </template>
            </van-cell>
        </van-cell-group>
        <van-goods-action style="background: none;">
            <template #default>
                <div class="large_button">
                    <van-button round color="linear-gradient(to right,#ff6034,#ee0a24)" v-if="activity.text.permit === 'participate'" :loading="loading" @click="$refs.exploreService.init(4)">{{activity.text.button}}</van-button>
                    <van-button round color="linear-gradient(to right,#ff6034,#ee0a24)" v-else-if="activity.text.permit === 'receive'" @click="$router.push({ path: '/lottery/confirm', query: { act_id: act_id }})">{{activity.text.button}}</van-button>
                    <van-button round color="linear-gradient(to right,#ff6034,#ee0a24)" v-else-if="activity.text.permit === 'use'" @click="$router.push({ path: '/lottery/consume', query: { act_id: act_id }})">{{activity.text.button}}</van-button>
                    <van-button round v-else disabled>{{activity.text.button}}</van-button>
                </div>
            </template>
        </van-goods-action>
        <explore-service ref="exploreService" @confirmRule="submit"></explore-service>
    </div>
</template>

<script>
    import {ImagePreview, Dialog, Toast} from 'vant';
    import ExploreService from "../Common/Dialog/ExploreService";
    import wx from "weixin-js-sdk";
    import {mapState} from 'vuex';

    export default {
        name: "LotteryDetail",
        components:{
            ExploreService,
        },
        computed:{
            ...mapState(['common']),
        },
        data() {
            return {
                act_id: 0,
                activity: {
                    text:{},
                    link_url: '',
                    goods_imgs: [],
                },
                errMsg:'',
                loading:false,
            }
        },
        async mounted() {
            let that = this;
            this.act_id = this.$route.query.act_id;

            await this.loadData();
            this.post('/api/common/jsapi',{url:location.href.split('#')[0]}).then(result=>{
                if(result.code === 0){
                    wx.config(result.data);
                }
            });
            wx.ready(function () {
                console.log('wx.ready');

                wx.updateAppMessageShareData({
                    title: '免费体验-' + that.activity.goods_name, // 分享标题
                    desc: '', // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: that.activity.title_img, // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })

                wx.updateTimelineShareData({
                    title: '免费体验-' + that.activity.goods_name, // 分享标题
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: that.activity.title_img, // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })

            });
            wx.error(function (res) {
                console.log(res);
            });
        },
        methods: {
            loadData() {
                let that = this;
                return new Promise((resolve, reject) => {
                    this.post('/api/client/activity/talent/detail', {act_id: this.act_id,adcode:this.common.address.adcode}).then(result => {
                        if (result.code === 0) {
                            that.activity = result.data;
                            that.errMsg = result.msg
                            resolve();
                        } else {
                            Dialog({message: result.msg});
                            resolve();
                        }
                    })
                });
            },
            imageDetail(index) {
                ImagePreview({
                    images: this.activity.goods_imgs,
                    startPosition: index,
                })
            },
            // 参与抽奖
            submit() {
                let that = this;

                // 先判断是否不在当前城市
                if(this.errMsg !== ''){
                    this.$dialog.confirm({
                        title: '提示',
                        message: this.errMsg,
                        confirmButtonText:'返回首页',
                        cancelButtonText:'取消',
                    }).then(()=>{
                        that.$router.replace('/lottery');
                    });
                }

                this.loading = true;
                this.post('/api/client/activity/lottery/participate', {act_id: this.act_id}).then(result => {
                    if (result.code === 0) {
                        // 参与成功,跳转到参与成功页面
                        that.$router.push({path: '/lottery/success', query: result.data});
                        this.loading = false;
                    } else if(result.code === -2) {
                        this.$dialog.confirm({
                            title: '提示',
                            message: result.msg,
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                        }).then(() => {
                            that.$router.replace('/talent/list');
                        });
                    } else if (result.code === -3){
                        this.$dialog.confirm({
                            title: '提示',
                            message: result.msg,
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                        }).then(() => {
                            that.$router.replace({path:'/subscribe',query:{url: location.href}});
                        });
                    } else {
                        Dialog({message: result.msg});
                        this.loading = false;
                    }
                }).catch(e=>{
                    this.loading = false;
                })
            },
            toLink(url){
                let link_url = url.substr(url.indexOf('http'));
                Dialog.confirm({
                    title:'请选择',
                    message:'您希望的操作是？',
                    confirmButtonText:'复制链接',
                    cancelButtonText:'直接跳转',
                }).then(()=>{
                    this.$copyText(link_url).then(res => {
                        Toast('已复制店铺链接到剪贴板,您可使用外部浏览器打开');
                    }).catch(err => {
                        alert('复制链接');
                    })
                }).catch(()=>{
                    Dialog.confirm({
                        title:'提示',
                        message:'您即将离开来飞网，跳转到商家提供的外部链接',
                    }).then(()=>{
                        location.replace(link_url);
                    })
                });
            }
        }
    }
</script>

<style scoped>
    .large_button {
        width: 100%;
        padding: 5px 10px;
    }
    .large_button button {
        height: 40px;
        width: 100%;
    }
</style>
